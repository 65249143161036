import React, { useState } from 'react';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBNavbarLink,
    MDBIcon,
    MDBCollapse,
    MDBBtn
} from 'mdb-react-ui-kit';

const Header = () => {
    const [openNavSecond, setOpenNavSecond] = useState(false);

    return (
        <MDBNavbar expand='lg' light bgColor='light'>
            <MDBContainer fluid>
                <MDBNavbarBrand href='#' className="fw-bold">Virtual Realty  </MDBNavbarBrand>
                <MDBNavbarToggler
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={() => setOpenNavSecond(!openNavSecond)}
                >
                    <MDBIcon icon='bars' fas />
                </MDBNavbarToggler>
                <MDBCollapse navbar open={openNavSecond}>
                    <MDBNavbarNav>
                        <MDBNavbarLink active aria-current='page' href='/'>
                            Home
                        </MDBNavbarLink>
                        <MDBNavbarLink href='/todo'>
                            To Dos
                        </MDBNavbarLink>
                        <MDBNavbarLink href='/about'>
                            About
                        </MDBNavbarLink>
                        <MDBNavbarLink href='/Contact'>
                            Contact
                        </MDBNavbarLink>
                    </MDBNavbarNav>
                </MDBCollapse>
                <div className='d-flex align-items-center me-2 me-lg-3'>
                    <MDBDropdown>
                        <MDBDropdownToggle style={{backgroundColor:"#adadad", borderColor:"#adadad"}}>Dropdown button</MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem link>Profile</MDBDropdownItem>
                            <MDBDropdownItem link>Account Settings</MDBDropdownItem>
                            <MDBDropdownItem link>Logout</MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </div>
            </MDBContainer>
        </MDBNavbar>
    );
}

export default Header;


