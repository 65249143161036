import React from 'react';
import {MDBCarousel, MDBCarouselItem, MDBCarouselCaption, MDBBtn, MDBIcon} from 'mdb-react-ui-kit';
import realtorWorking from '../images/RealtorWorking.png';
import soldProperty from '../images/SoldProperty.png';
import virtualAssistant from '../images/VirtualRealtyAssistant.png';

const LearnMorePage = () => {
    return (
        <MDBCarousel showIndicators showControls fade>
            <MDBCarouselItem itemId={1}>
                <img src={realtorWorking} className='d-block w-100' alt='...' />
                <MDBCarouselCaption>
                    <h5>Streamline Your Workflow with Intelligent Automation</h5>
                    <p>Free up valuable time by automating repetitive tasks like client communication, property listings, and paperwork. Our AI assistant handles the routine, so you can focus on what you do best—building relationships and closing deals.</p>
                    <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: '#3b5998'}}>
                        {/*<MDBIcon fab icon="facebook-f" className="mx-2"/>*/}
                        Get Started
                    </MDBBtn>
                </MDBCarouselCaption>
            </MDBCarouselItem>

            <MDBCarouselItem itemId={2}>
                <img src={soldProperty} className='d-block w-100' alt='...' />
                <MDBCarouselCaption>
                    <h5>Gain Insights with Advanced Market Analysis</h5>
                    <p>Stay ahead of the competition with real-time market insights and trend analysis. Our AI provides actionable data to help you make informed decisions, optimize pricing strategies, and spot opportunities before anyone else.</p>
                </MDBCarouselCaption>
            </MDBCarouselItem>

            <MDBCarouselItem itemId={3}>
                <img src='https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg' className='d-block w-100' alt='...' />
                <MDBCarouselCaption>
                    <h5>Enhance Client Experience with Seamless Interactions</h5>
                    <p>Deliver exceptional service with AI-driven virtual tours, personalized communication, and efficient scheduling. Our assistant ensures a smooth, professional experience for your clients, enhancing satisfaction and boosting your reputation.</p>
                </MDBCarouselCaption>
            </MDBCarouselItem>
        </MDBCarousel>
    );
}

export default LearnMorePage;
