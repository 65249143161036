import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
// import '@babel/plugin-proposal-decorators';
// @import '~mdb-ui-kit/css/mdb.min.css';
import "./styles/App.scss";
import WelcomePage from './pages/WelcomePage';
import LearnMorePage from './pages/LearnMorePage';
import GetStartedPage from './pages/GetStartedPage';
import PricingPage from './pages/PricingPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import RegistrationSelectionPage from './pages/RegistrationSelectionPage';
import PropertySitePage from './pages/PropertySitePage';
// import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import ForgotPassword from './pages/ForgotPassword';
import CustomizePage from './pages/CustomizePage';
import ToDoPage from './pages/ToDoPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import Header from './components/Header';
import ChatInterface from './components/ChatInterface';
import NotFoundPage from './pages/NotFoundPage';
import Login from './components/Login';
import Register from './components/Register';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './components/AuthContext';
import GuestLayout from './pages/GuestLayout'; // Import GuestLayout component
import AuthenticatedLayout from './pages/AuthenticatedLayout'; // Import AuthenticatedLayout component
import { useAuth } from './components/AuthContext';

const AppRoutes = () => {
  const { user } = useAuth(); // Get user from context

  return (
      <Routes>
        {! user ? (
            // Routes for guests (not logged in)
            <Route path="/" element={<GuestLayout />}>
                <Route path="/s/:propertyId" element={<PropertySitePage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/learn-more" element={<LearnMorePage />} />
                <Route path="/get-started" element={<GetStartedPage />} />
                <Route path="/customize" element={<CustomizePage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/register" element={<RegistrationSelectionPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="*" element={<HomePage />} /> {/* Default to WelcomePage */}
                <Route path="/" element={<HomePage />} /> {/* Default to WelcomePage */}
            </Route>
        ) : (
            // Routes for authenticated users
            <Route path="/" element={<AuthenticatedLayout />}>
              <Route path="/home" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/todo" element={<ToDoPage />} />
                <Route path="/s/:propertyId" element={<PropertySitePage />} />

                <Route path="*" element={<HomePage />} /> {/* Default to HomePage */}
            </Route>
        )}
      </Routes>
  );
};

const App = () => {
  return (
      <Router>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </Router>
  );
};

export default App;
