import React from 'react';
import "../styles/App.scss";
import AddTodo from "../components/AddTodo";
import TodoList from "../components/TodoList";
import axios from "axios";

const ToDoPage = () => {
    // constructor(props) {
    //     super(props);
    //
    //     this.state = {
    //         todos: [],
    //     };
    // }
    //
    // componentDidMount() {
    //     axios
    //         .get("/api")
    //         .then((response) => {
    //             this.setState({
    //                 todos: response.data.data,
    //             });
    //         })
    //         .catch((e) => console.log("Error : ", e));
    // }
    const componentDidMount = () => {
        axios
            .get("/api")
            .then((response) => {
                this.setState({
                    todos: response.data.data,
                });
            })
            .catch((e) => console.log("Error : ", e));
    }
    const handleAddTodo = (value) => {
        axios
            .post("/api/todos", { text: value })
            .then(() => {
                this.setState({
                    todos: [...this.state.todos, { text: value }],
                });
            })
            .catch((e) => console.log("Error : ", e));
    };

    return (
        <div className="App container">
            <h1>To Do Page</h1>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12 col-sm-8 col-md-8 offset-md-2">
                        <h1>Todos</h1>
                        <div className="todo-app">
                            <AddTodo handleAddTodo={this.handleAddTodo} />
                            <TodoList todos={this.state.todos} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ToDoPage;
