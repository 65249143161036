import React, {useEffect, useRef, useState} from 'react';
import virtualAssistant from '../images/VirtualRealtyAssistant.png';
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn,
    MDBIcon,
    MDBInput,
    MDBCheckbox
} from 'mdb-react-ui-kit';
import '../styles/LoginPage.scss';
import {useAuth} from "../components/AuthContext"; // Import your CSS file

const LoginPage = () => {
    const { handleLogin } = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(0);

    const handleSignIn = async (e) => {
        e.preventDefault();
        await handleLogin({ email, password });
    };

    return (
        <MDBContainer fluid className="p-3 my-5 login-container">
            <MDBRow>
                <MDBCol col='10' md='6'>
                    <img
                        src={virtualAssistant}
                        className="img-fluid"
                        alt="Phone image"
                    />
                </MDBCol>

                <MDBCol col='4' md='6'>
                    <MDBInput
                        wrapperClass='mb-4'
                        label='Email address'
                        id='email'
                        type='email'
                        size="lg"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <MDBInput
                        wrapperClass='mb-4'
                        label='Password'
                        id='password'
                        type='password'
                        size="lg"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <div className="d-flex justify-content-between mx-4 mb-4">
                        <MDBCheckbox
                            name='flexCheck'
                            value={rememberMe}
                            id='rememberMeCheckBox'
                            label='Remember me' />
                        <a href="/forgot-password">Forgot password?</a>

                    </div>

                    <MDBBtn
                        className="mb-4 w-100"
                        size="lg"
                        onClick={handleSignIn}
                    >
                        Sign in
                    </MDBBtn>

                    <div className="divider d-flex align-items-center my-4">
                        <p className="text-center fw-bold mx-3 mb-0">OR</p>
                    </div>

                    <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: '#55acee'}}>
                        <MDBIcon fab icon="google" className="mx-2"/>
                        Continue with Gmail
                    </MDBBtn>

                    <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: '#3b5998'}}>
                        <MDBIcon fab icon="facebook-f" className="mx-2"/>
                        Continue with facebook
                    </MDBBtn>

                </MDBCol>

            </MDBRow>

        </MDBContainer>
    );
};

export default LoginPage;
