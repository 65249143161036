import React from 'react';
import Header from '../components/Header'; // Import your header component
import {
    MDBContainer,
    MDBRow,
    MDBCol,
} from "mdb-react-ui-kit";
import {Outlet, Routes} from 'react-router-dom';
import ChatInterface from "../components/ChatInterface"; // This will render the nested routes

const AuthenticatedLayout = () => {
    return (
        <div>
            <Header /> {/* Display the header */}
            <MDBContainer fluid className="py-5">
                <MDBRow>
                    {/* Left Side: AI Interface */}
                    <MDBCol md="6" lg="5" xl="4" className="mb-4 mb-md-0">
                        <ChatInterface />
                    </MDBCol>
                    {/* Right Side: Application Work Area */}
                    <MDBCol md="6" lg="7" xl="8">
                        {/* Application content goes here */}
                        <Outlet /> {/* This will render child routes for authenticated users */}
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
};

export default AuthenticatedLayout;
