import React, {useState} from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardHeader,
    MDBCardFooter,
    MDBBtn, MDBInput, MDBContainer
} from "mdb-react-ui-kit";

const ForgotPassword = () => {
    const [email, setEmail] = useState('');

    const handlePasswordRequest = () => {
        email ?
            alert("Whomp Whomp Whomp... You should've wrote it down")
            : alert("Please enter an email address");
    }

    return (
        <MDBContainer fluid>
            <MDBCard className='m-5' alignment='center' style={{borderRadius: '25px'}}>
                <MDBCardHeader className='h1 fw-bold mb-2 mx-1 mx-md-4 mt-4'>Forgot Password</MDBCardHeader>
                <MDBCardBody>
                    <MDBCardText>Enter your email address and we'll send a link to reset your password</MDBCardText>
                    <MDBInput
                        label='Email address'
                        type='email'
                        id='email'
                        className="form-control"
                        size='lg'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <MDBBtn
                        className='my-4'
                        onClick={handlePasswordRequest}
                        >
                        Request Password
                    </MDBBtn>
                </MDBCardBody>
                <MDBCardFooter
                    className='text-muted'>
                    <div className="d-flex me-2 justify-content-between">
                        <MDBBtn
                            color='link'
                            rippleColor='dark'
                            href='javascript:history.back()'
                        >
                            Back to Login
                        </MDBBtn>
                        <MDBBtn
                            color='link'
                            rippleColor='dark'
                            href='./register'
                        >
                            Register
                        </MDBBtn>
                    </div>
                </MDBCardFooter>
            </MDBCard>
        </MDBContainer>
    );
}

export default ForgotPassword;
