import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom'; // This will render the nested routes
import Header from '../components/GuestHeader';
import { usePageId } from '../components/PageIdContext'; // Import usePageId

const GuestLayout = () => {
    // const location = useLocation();
    // const { setPageId } = usePageId();
    //
    // useEffect(() => {
    //     // Set the id of the root element based on the current pathname
    //     const pageName = location.pathname.replace('/', '') || 'home'; // Default to 'home'
    //     setPageId('page-${pageName}');
    // }, [location, setPageId]);

    return (
        <div>
            {/* Content for guests */}
            <Outlet /> {/* This will render child routes for guests */}
        </div>
    );
};

export default GuestLayout;
