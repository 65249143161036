import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/PropertySitePage.css'; // Import your CSS file

import {
    MDBCarousel,
    MDBCarouselItem,
    MDBCarouselCaption, MDBBtn,
    MDBNavbar,
    MDBNavbarToggler, MDBIcon, MDBInput,
    MDBTextArea
} from 'mdb-react-ui-kit';
import realtorWorking from "../images/RealtorWorking.png";
import soldProperty from "../images/SoldProperty.png";
const PropertySitePage = () => {
    const { userId } = useParams();
    const [openBasic, setOpenBasic] = useState(false);
    const [name, setName] = useState(false);
    const [email, setEmail] = useState(false);
    const [subject, setSubject] = useState(false);

    // Fetch all the information for the website using the ID and render the site accordingly

    // Nav Bar
    const navbar = (
        <MDBNavbar expand='lg' className="navbar navbar-expand-lg fixed-top navbar-scroll">
            <div className="container">
                <a className="navbar-brand" href="#">Brand</a>
                <MDBNavbarToggler
                    className="navbar-toggler"
                    data-mdb-target="#navbarSupportedContent"
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={() => setOpenBasic(!openBasic)}
                >
                    <MDBIcon icon='bars' fas />
                </MDBNavbarToggler>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#home">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#features">Price & Features</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#description">Description</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#amenities">Amenities</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#videos">Videos</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#photos">Photos</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#floor-plans">Floor Plans</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#map">Map</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#documents">Documents</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#flyers">Flyers</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#agent">Agent</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#share">Share</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#request-info">Request Info</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav d-flex flex-row">
                        <li className="nav-item me-3 me-lg-0">
                            <a className="nav-link" href="#">
                                <i className="fas fa-shopping-cart"></i>
                            </a>
                        </li>
                        <li className="nav-item me-3 me-lg-0">
                            <a className="nav-link" href="#">
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li className="nav-item me-3 me-lg-0">
                            <a className="nav-link" href="#">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </MDBNavbar>
    );

    // Home - Top section with photo slideshow and menu
        const home = (
            <MDBCarousel showIndicators showControls fade>
                <MDBCarouselItem itemId={1}>
                    <img src='https://mdbootstrap.com/img/Photos/Slides/img%20(15).webp' className='d-block w-100' alt='...' />
                    <MDBCarouselCaption>
                        <h5>First slide label</h5>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </MDBCarouselCaption>
                </MDBCarouselItem>

                <MDBCarouselItem itemId={2}>
                    <img src='https://mdbootstrap.com/img/Photos/Slides/img%20(22).webp' className='d-block w-100' alt='...' />
                    <MDBCarouselCaption>
                        <h5>Second slide label</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </MDBCarouselCaption>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={3}>
                    <img src='https://mdbootstrap.com/img/Photos/Slides/img%20(23).webp' className='d-block w-100' alt='...' />
                    <MDBCarouselCaption>
                        <h5>Third slide label</h5>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                    </MDBCarouselCaption>
                </MDBCarouselItem>
            </MDBCarousel>
        );

    // Video
    const video = (
        <section id="home">
            <div id="intro" className="bg-image vh-100">
                <div className="ratio ratio-16x9">
                    <iframe
                        src="https://www.youtube.com/embed/vlDzYIIOYmM"
                        title="YouTube video"
                        allowFullScreen
                    ></iframe>
                </div>

            </div>
        </section>
    );

    // About Us
    const aboutUs = (
        <div className="container my-5">
            <section>
                <h3 className="text-center mb-5">About us</h3>

                <div className="row">
                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <h5 className="text-dark">01 | We are creative <i className="fas fa-brain"></i></h5>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, aliquam dolor
                            soluta praesentium natus alias eligendi.
                        </p>
                    </div>

                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <h5 className="text-dark">02 | We are competent <i className="fas fa-puzzle-piece"></i></h5>
                        <p>
                            A expedita facilis enim et sequi sit similique fuga deleniti assumenda. Quisquam ea
                            corrupti laudantium assumenda.
                        </p>
                    </div>

                    <div className="col-lg-4 mb-0">
                        <h5 className="text-dark">03 | We are experienced <i className="fas fa-lightbulb"></i></h5>
                        <p>
                            Oluptatum autem veniam quaerat sequi odit sit aliquam expedita, ab, excepturi
                            pariatur natus magnam possimus.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
    // Price & Features
    const features = (
        <section id="features">
            <div className="bg-image bg-fixed text-center" style={{
                backgroundImage: "url('https://mdbootstrap.com/img/Photos/Horizontal/Things/full page/img%20%287%29.jpg')",
                height: "460px" }}>
                <div className="d-flex align-items-center h-100 mask" style={{
                    background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}}>
                    <div className="container text-white">
                        <p className="h1 mb-5">Great people trusted our services</p>
                        <div className="row">
                            <div className="col-md-3">
                                <h2 className="h1">+950</h2>
                                <p className="mb-md-0">Happy clients</p>
                            </div>
                            <div className="col-md-3">
                                <h2 className="h1">+150</h2>
                                <p className="mb-md-0">Projects completed</p>
                            </div>
                            <div className="col-md-3">
                                <h2 className="h1">+85</h2>
                                <p className="mb-md-0">Winning awards</p>
                            </div>
                            <div className="col-md-3">
                                <h2 className="h1">+246</h2>
                                <p className="mb-0">Cups of coffee</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

    // Amenities
    const amenities = (
        <div id="amenities"></div>
    );

    // Description
    const description = (
        <section id="description">
            <div className="bg-light" style={{height: "400px"}}>
                <div className="d-flex align-items-center justify-content-center text-center h-100">
                    <div className="container">
                        <p><strong>SINCE 2010</strong><i className="far fa-calendar-alt ms-2"></i></p>
                        <h3 className="text-uppercase font-weight-bold my-4 py-2">
                            We create extraordinary projects for our clients
                        </h3>
                        <p className="mb-0">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo animi soluta ratione
                            quisquam, dicta ab cupiditate iure eaque? Repellendus voluptatum, magni impedit
                            eaque delectus, beatae maxime temporibus maiores quibusdam quasi. Rem magnam ad
                            perferendis iusto sint tempora ea voluptatibus iure, animi excepturi modi aut
                            possimus.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );

    const videos = (
        <div id="videos"></div>
    );

    // Photos & Slideshow
    const photos = (
        <div className="container-fluid my-5" id="photos">
            <section className="text-center">
                <div className="container">
                    <h3 className="mb-5">Our projects</h3>

                    <p className="mb-5 mx-auto w-responsive">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum laborum animi
                        doloremque nam dicta veniam dolor ea nostrum excepturi laboriosam molestiae, expedita
                        odit id! Sunt quia cumque consequatur perferendis hic.
                    </p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-4">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <img src="https://mdbootstrap.com/img/Photos/Others/img3.jpg" className="w-100" alt="Sample image" />
                            <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                <MDBBtn outline className='mx-2' color='info'>
                                    SEE MORE
                                </MDBBtn>
                            </div>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Lorem ipsum dolor sit</p>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <img src=" https://mdbootstrap.com/img/Photos/Others/img4.jpg" className="w-100" alt="Sample image" />
                            <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                <MDBBtn outline className='mx-2' color='info'>
                                    SEE MORE
                                </MDBBtn>
                            </div>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Lorem ipsum dolor sit</p>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <img src="https://mdbootstrap.com/img/Photos/Others/img8.jpg" className="w-100" alt="Sample image" />
                            <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                <MDBBtn outline className='mx-2' color='info'>
                                    SEE MORE
                                </MDBBtn>
                            </div>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Lorem ipsum dolor sit</p>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-0">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <img src=" https://mdbootstrap.com/img/Photos/Others/img6.jpg" className="w-100" alt="Sample image" />
                            <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                <MDBBtn outline className='mx-2' color='info'>
                                    SEE MORE
                                </MDBBtn>
                            </div>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Lorem ipsum dolor sit</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <img src="https://mdbootstrap.com/img/Photos/Others/img7.jpg" className="w-100" alt="Sample image" />
                            <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                <MDBBtn outline className='mx-2' color='info'>
                                    SEE MORE
                                </MDBBtn>
                            </div>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Lorem ipsum dolor sit</p>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <img src="https://mdbootstrap.com/img/Photos/Others/img5.jpg" className="w-100" alt="Sample image" />
                            <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                <MDBBtn outline className='mx-2' color='info'>
                                    SEE MORE
                                </MDBBtn>
                            </div>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Lorem ipsum dolor sit</p>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <img src="https://mdbootstrap.com/img/Photos/Others/img9.jpg" className="w-100" alt="Sample image" />
                            <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                <MDBBtn outline className='mx-2' color='info'>
                                    SEE MORE
                                </MDBBtn>
                            </div>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Lorem ipsum dolor sit</p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <img src="https://mdbootstrap.com/img/Photos/Others/img10.jpg" className="w-100" alt="Sample image" />
                            <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                <MDBBtn outline className='mx-2' color='info'>
                                    SEE MORE
                                </MDBBtn>
                            </div>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Lorem ipsum dolor sit</p>
                    </div>
                </div>
            </section>
        </div>
    );

    const floorPlans = (
        <div id="floor-plans"></div>
    );

    // Map
    const map = (
        <div id="map"></div>
    );
    // Documents
    const documents = (
        <div className="container my-5" id="documents">
            <section className="text-center">


                <h3 className="text-center mb-5">What we do</h3>

                <div className="row">
                    <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                        <div className="card hover-shadow-light">
                            <div className="card-body">
                                <i className="fas fa-laptop fa-3x mt-3"></i>
                                <h5 className="card-title my-4">Web design</h5>
                                <p className="card-text mb-3">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                        <div className="card hover-shadow-light">
                            <div className="card-body">
                                <i className="far fa-newspaper fa-3x mt-3"></i>
                                <h5 className="card-title my-4">Marketing</h5>
                                <p className="card-text mb-3">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                        <div className="card hover-shadow-light">
                            <div className="card-body">
                                <i className="fas fa-pencil-alt fa-3x mt-3"></i>
                                <h5 className="card-title my-4">Branding</h5>
                                <p className="card-text mb-3">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-0">
                        <div className="card hover-shadow-light">
                            <div className="card-body">
                                <i className="fas fa-camera-retro fa-3x mt-3"></i>
                                <h5 className="card-title my-4">Photography</h5>
                                <p className="card-text mb-3">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

    const flyers = (
        <div id="flyers"></div>
    );

    // Agent
    const agent = (
        <div id="agent"></div>
    );
    // Share

    // Social icons
    const social = (
        <div className="container d-flex justify-content-center text-center py-5" id="share">
            <div className="row">
                <div className="col-12">
                    <MDBBtn floating size='lg' tag='a'>
                        <MDBIcon fab icon='facebook-f' />
                    </MDBBtn>
                    <MDBBtn floating size='lg' tag='a'>
                        <MDBIcon fab icon='twitter' />
                    </MDBBtn>
                    <MDBBtn floating size='lg' tag='a'>
                        <MDBIcon fab icon='google-plus-g' />
                    </MDBBtn>
                    <MDBBtn floating size='lg' tag='a'>
                        <MDBIcon fab icon='youtube' />
                    </MDBBtn>
                    <MDBBtn floating size='lg' tag='a'>
                        <MDBIcon fab icon='instagram' />
                    </MDBBtn>
                </div>
            </div>
        </div>
    );

    // Request Info / Book Appointment
    const contactUs = (
        <div className="container my-5" id="request-info">
            <section className="text-center mb-5">
                <h3 className="mb-5">Contact us</h3>

                <p className="text-center mb-5 mx-auto w-responsive">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum laborum animi doloremque
                    nam dicta veniam dolor ea nostrum excepturi laboriosam molestiae, expedita odit id! Sunt
                    quia cumque consequatur perferendis hic.
                </p>

                <form>
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row mb-4">
                                <div className="col-md-6 mb-4 mb-md-0 ">
                                    <MDBInput
                                        label="First Name"
                                        type="text"
                                        id="name"
                                        className="form-control w-100"
                                        value={name}
                                        required
                                    />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput
                                        label="Email"
                                        type="text"
                                        id="email"
                                        className="form-control w-100"
                                        value={email}
                                        required
                                    />
                                </div>
                            </div>
                            <MDBInput
                                label="Subject"
                                type="text"
                                id="subject"
                                className="form-control w-100"
                                value={subject}
                                required
                            />
                            <MDBTextArea label="Message" id="textAreaExample" rows="{4}" />
                            <div className="text-center text-md-start">
                                <MDBBtn outline className='mx-2' color='info'>
                                    Send
                                </MDBBtn>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <ul className="list-unstyled mb-0">
                                <li>
                                    <i className="fas fa-map-marker-alt fa-2x text-dark"></i>
                                    <p>New York, NY 10012, USA</p>
                                </li>
                                <li>
                                    <i className="fas fa-phone fa-2x text-dark"></i>
                                    <p>+ 01 234 567 89</p>
                                </li>
                                <li>
                                    <i className="fas fa-envelope fa-2x text-dark"></i>
                                    <p className="mb-0">contact@gmail.com</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    );

    const footer = (
        <footer className="bg-light">
            <div className="text-center p-3" style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
                © 2020 Copyright:
                <a className="text-white" href="https://mdbootstrap.com/">MDBootstrap.com</a>
            </div>
        </footer>
    );

    return (
    <div>
        <header>
            { navbar }

            { video }
        </header>
    
        <main>
            { features }

            { description }

            { amenities }

            { videos }

            { photos }

            { floorPlans }

            { map }

            { documents }

            { flyers }

            { agent }

            { social }

            { contactUs }

            { aboutUs }
        </main>

            { footer }
    </div>);
};

export default PropertySitePage;
