import React, {useEffect} from 'react';
// import VectorMap from '../plugins/vector-map/mdb-react-vector-maps';
const GetStartedPage = () => {
    // useEffect(() => {
    //     const map = document.getElementById('my-map');
    //
    //     new VectorMap(map, {
    //         readonly: true,
    //         stroke: '#fff',
    //         colorMap: [
    //             {
    //                 fill: '#E0E0E0',
    //                 regions: ['GB', 'PL']
    //             }
    //         ]
    //     })
    // })

    return <div id="my-map"></div>
};

export default GetStartedPage;
