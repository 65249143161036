import React, { createContext, useContext, useState, useEffect } from 'react';
import { isAuthenticated, getCurrentUser, login, logout, register } from '../services/authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (isAuthenticated()) {
            setUser(getCurrentUser());
        }
    }, []);

    const handleLogin = async (userData) => {
        const data = await login(userData);
        localStorage.setItem('token', data.token);
        setUser(getCurrentUser());
    };

    const handleLogout = () => {
        logout();
        setUser(null);
    };

    const handleRegister = async (userData) => {
        await register(userData);
    };

    return (
        <AuthContext.Provider value={{ user, handleLogin, handleLogout, handleRegister }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
