import React from 'react';
import '../styles/TermsAndConditionsPage.scss'; // Import your CSS file

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h1>Terms and Conditions</h1>
            <p>Last Updated: August 16, 2024</p>
            <p>Welcome to Virtual Realty ("we," "our," "us"). These Terms and Conditions ("Terms") govern your use of our subscription-based web application ("Service"), which includes AI functionality to interface with Multiple Listing Services (MLS) and automate tasks for realtors. By accessing or using our Service, you agree to these Terms. If you do not agree, please do not use our Service.</p>

            <h2>1. Acceptance of Terms</h2>
            <p>By using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms. We may update these Terms from time to time, and your continued use of the Service signifies your acceptance of any changes.</p>

            <h2>2. Subscription and Payments</h2>
            <p><strong>Subscription Plans:</strong> Our Service offers various subscription plans. Details of each plan, including pricing and features, are available on our website.</p>
            <p><strong>Billing:</strong> Subscription fees are billed in advance on a recurring basis. Payments are non-refundable except as required by law.</p>
            <p><strong>Cancellation:</strong> You may cancel your subscription at any time. Upon cancellation, you will retain access to the Service until the end of the current billing period.</p>

            <h2>3. Use of Service</h2>
            <p><strong>License:</strong> We grant you a limited, non-exclusive, non-transferable license to access and use the Service for its intended purpose.</p>
            <p><strong>Prohibited Activities:</strong> You agree not to:
                <ul>
                    <li>Use the Service for any illegal or unauthorized purpose.</li>
                    <li>Distribute or share access to the Service with unauthorized parties.</li>
                    <li>Modify, adapt, or create derivative works based on the Service without our written permission.</li>
                    <li>Interfere with or disrupt the Service or servers.</li>
                </ul>
            </p>

            <h2>4. AI Functionality and MLS Integration</h2>
            <p><strong>AI Limitations:</strong> The AI functionality provided by our Service is designed to assist with tasks related to MLS and real estate. However, AI technology is inherently imperfect and may not always perform accurately or as expected. We make no guarantees regarding the accuracy, completeness, or reliability of AI-generated results or automated tasks.</p>
            <p><strong>No Professional Advice:</strong> The Service is not intended to provide professional advice. You are responsible for verifying the accuracy and suitability of any information or recommendations provided by the AI.</p>

            <h2>5. Data and Privacy</h2>
            <p><strong>Data Collection:</strong> We may collect and store personal and usage data as described in our Privacy Policy.</p>
            <p><strong>Data Security:</strong> We implement reasonable measures to protect your data, but we cannot guarantee absolute security.</p>
            <p><strong>Third-Party Services:</strong> Our Service may integrate with third-party services. We are not responsible for the privacy practices or security of these third parties.</p>

            <h2>6. Disclaimers and Limitation of Liability</h2>
            <p><strong>Disclaimer:</strong> The Service is provided "as is" and "as available." We disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.</p>
            <p><strong>Limitation of Liability:</strong> To the maximum extent permitted by law, we are not liable for any indirect, incidental, consequential, or punitive damages arising from your use of the Service or inability to use it.</p>

            <h2>7. Indemnification</h2>
            <p>You agree to indemnify and hold us harmless from any claims, damages, liabilities, and expenses arising from your use of the Service, your violation of these Terms, or your infringement of any rights of a third party.</p>

            <h2>8. Termination</h2>
            <p>We reserve the right to suspend or terminate your access to the Service if you violate these Terms or if we believe that such action is necessary to protect the Service or our users.</p>

            <h2>9. Governing Law</h2>
            <p>These Terms are governed by and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law principles. Any disputes arising from these Terms shall be resolved in the courts of [Your State/Country].</p>

            <h2>10. Contact Us</h2>
            <p>If you have any questions about these Terms or the Service, please contact us at:</p>
            <p><strong>Email:</strong> support@virtualrealty.com</p>
            <p><strong>Address:</strong> [Your Address]</p>
        </div>
    );
};

export default TermsAndConditions;
