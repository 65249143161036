import React from 'react';
import '../styles/PrivacyPolicyPage.scss'; // Import your CSS file

const PrivacyPolicy = () => {
    return (
        <div className="privacy-container">
            <h1>Privacy Policy</h1>
            <p>Last Updated: August 16, 2024</p>
            <p>At Virtual Realty ("we," "our," "us"), we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy ("Policy") explains how we collect, use, and safeguard your data when you use our subscription-based web application ("Service"), which includes AI functionality to interface with Multiple Listing Services (MLS) and automate tasks for realtors. By using our Service, you consent to the practices described in this Policy.</p>

            <h2>1. Information We Collect</h2>
            <p>We collect various types of information to provide and improve our Service:</p>
            <p><strong>Personal Information:</strong> When you register for an account, we collect personal information such as your name, email address, phone number, and payment details.</p>
            <p><strong>Usage Data:</strong> We collect information about how you interact with our Service, including your IP address, browser type, operating system, pages visited, and the duration of your visits.</p>
            <p><strong>AI Interaction Data:</strong> Data related to your interactions with our AI features, including commands given and responses received, is collected to enhance the performance of our AI and the Service.</p>

            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect for various purposes:</p>
            <p><strong>To Provide and Maintain the Service:</strong> We use your data to operate, maintain, and improve our Service and its features.</p>
            <p><strong>To Process Transactions:</strong> We use payment information to process subscription fees and manage billing.</p>
            <p><strong>To Communicate With You:</strong> We may use your contact information to send updates, notifications, and promotional materials related to the Service. You can opt-out of promotional communications at any time.</p>
            <p><strong>To Analyze and Improve the Service:</strong> We analyze usage data to understand how our Service is used and to identify areas for improvement.</p>

            <h2>3. Data Sharing and Disclosure</h2>
            <p>We do not sell your personal information to third parties. We may share your data under the following circumstances:</p>
            <p><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our Service, such as payment processors and cloud storage providers. These providers are obligated to protect your information and use it only for the purposes for which it was shared.</p>
            <p><strong>Legal Requirements:</strong> We may disclose your data if required to do so by law or in response to a valid legal request, such as a subpoena or court order.</p>
            <p><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your data may be transferred to the acquiring entity.</p>

            <h2>4. Data Security</h2>
            <p>We implement reasonable technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

            <h2>5. Cookies and Tracking Technologies</h2>
            <p>We use cookies and similar tracking technologies to enhance your experience with our Service:</p>
            <p><strong>Cookies:</strong> Small data files placed on your device that help us remember your preferences and understand how you use our Service.</p>
            <p><strong>Tracking Technologies:</strong> Tools like web beacons and analytics services to monitor and analyze usage patterns.</p>
            <p>You can control cookie settings through your browser. However, disabling cookies may affect your ability to use certain features of our Service.</p>

            <h2>6. Your Rights and Choices</h2>
            <p>You have the following rights regarding your personal information:</p>
            <p><strong>Access and Correction:</strong> You can request access to and correction of your personal data.</p>
            <p><strong>Data Deletion:</strong> You may request the deletion of your personal data, subject to legal and contractual restrictions.</p>
            <p><strong>Opt-Out:</strong> You can opt out of receiving promotional communications from us by following the unsubscribe instructions in those communications.</p>

            <h2>7. Children’s Privacy</h2>
            <p>Our Service is not intended for individuals under the age of 13. We do not knowingly collect or solicit personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.</p>

            <h2>8. Changes to This Policy</h2>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Last Updated" date. Your continued use of the Service after any changes indicates your acceptance of the revised Policy.</p>

            <h2>9. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
            <p><strong>Email:</strong> privacy@virtualrealty.com</p>
            <p><strong>Address:</strong> [Your Address]</p>
        </div>
    );
};

export default PrivacyPolicy;
